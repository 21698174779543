<template>
  <div class="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
img {
  font-size: 0;
}
html {
  font-size: 10px;
}
.app {
  width: 100%;
  height: 100vh;
}
.omit1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.omit2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
